import request from '../utils/request'
export const personAlldata=(imei,date)=>{
    return request({
        url:'/admin/personAlldata',
        method:'post',
        data:{
            imei,
			date
        }
    })
}
export const getAllbarnchPersion=(imei,start_date,end_date)=>{
    return request({
        url:'/admin/getAllbarnchPersion',
        method:'post',
        data:{
            imei,
			start_date,
            end_date,
        }
    })
}
export const getAllbarnch=(company,phone,level)=>{
    return request({
        url:'/admin/getAllbarnch',
        method:'post',
        data:{
            company,
            phone,
            level,
        }
    })
}
export const add_department=(company,branch,branch_level,root_branch,p_branch)=>{
    return request({
        url:'/admin/department',
        method:'post',
        data:{
            company,
            branch,
            branch_level,
            root_branch,
            p_branch
        }
    })
}
export const department_list=(company,branch1,branch2,branch3,branch4,)=>{
    return request({
        url:'/admin/department',
        method:'get',
        params:{
            company,
            branch1,
            branch2,
            branch3,
            branch4,
        }
    })
}
export const edit_department=(id,department_name,company,department_old)=>{
    return request({
        url:'/admin/department',
        method:'put',
        params:{
            id,
            department_name,
            company,
            department_old
        }
    })
}
export const remove_department=(id,company,department_old)=>{
    return request({
        url:'/admin/department',
        method:'delete',
        data:{
            id,
            company,
            department_old,
        }
    })
}