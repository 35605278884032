import request from '../utils/request'

export const equipment_list=(company,imei,branch1,branch2,pagenum,pagesize,version=0)=>{
    return request({
        url:'/admin/equipment',
        method:'get',
        params:{
            company,
            imei,
            branch1,
            branch2,
            pagenum,
            pagesize,
			version
        }
    })
}
export const bind_equipment=(phone,imei)=>{
    return request({
        url:'/admin/equipment',
        method:'post',
        data:{
            phone,
            imei
        }
    })
}
export const unbind_equipment=(imei)=>{
    return request({
        url:'/admin/equipment',
        method:'put',
        params:{
            imei
        }
    })
}
export const batch_bind_equipment=(formData)=>{
    return request({
        url:'/admin/batch_bind',
        method:'post',
        headers: {
            "Content-Type": "multipart/form-data"
        },
        data:formData
    })
}
export const batch_unbind_equipment=(formData)=>{
    return request({
        url:'/admin/batch_unbind',
        method:'post',
        headers: {
            "Content-Type": "multipart/form-data"
        },
        data:formData
    })
}
export const del_equipment=(formData)=>{
    return request({
        url:'/admin/del',
        method:'post',
        headers: {
            "Content-Type": "multipart/form-data"
        },
        data:formData
    })
}