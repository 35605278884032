import request from '../utils/request'

export const bian_log=(content)=>{
    return request({
        url:'/admin/bian_log',
        method:'post',
        data:{
			company:sessionStorage.getItem('company'),
            view:sessionStorage.getItem('pageName'),
            content,
            operation_name:sessionStorage.getItem('name'),
            operation_role:sessionStorage.getItem('level'),
            operation_time:new Date().getTime(),
        }
    })
}
export const log_list=(view,content,operation_name,start_time,end_time,pagesize,pagenum)=>{
    return request({
        url:'/admin/bian_log',
        method:'get',
        params:{
            view,
            content,
            operation_name,
            start_time,
            end_time,
            pagesize,
            pagenum
        }
    })
}