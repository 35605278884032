import request from '../utils/request'

export const add_personnel=(company,name,job,phone,gender,birthday,branch1,branch2,branch3,branch4)=>{
    return request({
        url:'/admin/personnel',
        method:'post',
        data:{
            company,
            name,
            job,
            phone,
			gender,
			birthday,
            branch1,
            branch2,
            branch3,
            branch4
        }
    })
}
export const personnel_list=(company,name,job,branch1,branch2,branch3,branch4,pagenum,pagesize)=>{
    return request({
        url:'/admin/personnel',
        method:'get',
        params:{
            company,
            name,
            job,
            branch1,
            branch2,
            branch3,
            branch4,
            pagenum,
            pagesize,
        }
    })
}
export const edit_personnel=(company,name,job,phone,gender,birthday,branch1,branch2,branch3,branch4,id,old_branch1,old_branch2,old_branch3,old_branch4)=>{
    return request({
        url:'/admin/personnel',
        method:'put',
        params:{
            company,
            name,
            job,
            phone,
			gender,
			birthday,
            branch1,
            branch2,
            branch3,
            branch4,
            id,
            old_branch1,
            old_branch2,
            old_branch3,
            old_branch4
        }
    })
}
export const remove_personnel=(company,id,branch1,branch2,branch3,branch4)=>{
    return request({
        url:'/admin/personnel',
        method:'delete',
        data:{
            company,
            id,
            branch1,
            branch2,
            branch3,
            branch4,
        }
    })
}
export const excel_upload=(formData)=>{
    return request({
        url:'/admin/excel_upload',
        method:'post',
        headers: {
            "Content-Type": "multipart/form-data"
        },
        data:formData
    })
}
