<template>
	<div class="container">
		<el-breadcrumb separator="/">
			<el-breadcrumb-item>管理后台</el-breadcrumb-item>
			<el-breadcrumb-item>设备管理</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card>
			<el-row>
				<el-col :span="24">
					<el-form :inline="true">
						<el-form-item>
							<el-input size="small" placeholder="IMEI号" v-model="searchImei"></el-input>
						</el-form-item>
						<el-form-item>
							<el-select v-model="searchDepartment1" size="small" placeholder="单位"
								:disabled="branchArr[0] ? true:false" @change="choiceA">
								<el-option v-for="item in department1List" :key="item.branch" :label="item.branch"
									:value="item.branch">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-select v-model="searchDepartment2" v-if="department2List.length>0" size="small"
								placeholder="班组" :disabled="branchArr[1] ? true:false">
								<el-option v-for="item in department2List" :key="item.branch" :label="item.branch"
									:value="item.branch">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" size="small" @click="reset">重置</el-button>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" size="small" @click="search">查询</el-button>
						</el-form-item>
						<el-form-item>
							<el-button type="success" size="small" v-if="personnelLink!=''" @click="exportData">
								<el-link :href="publicUrl+personnelLink" class="download">导出数据</el-link>
							</el-button>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" size="small" @click="addEquipment">添加设备</el-button>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" size="small" @click="batchBind">批量导入设备</el-button>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" size="small" @click="batchUnbind">批量解绑</el-button>
						</el-form-item>
					</el-form>
				</el-col>
			</el-row>
			<el-table :data="equipmentData" border stripe style="width: 100%">
				<el-table-column :show-overflow-tooltip="true" width="150px" prop="imei" label="IMEI">
				</el-table-column>
				<el-table-column :show-overflow-tooltip="true" prop="is_online" label="设备状态">
					<template slot-scope="scope">
						<el-tag type="success" size="mini" v-if="scope.row.is_online=='online'">在线</el-tag>
						<el-tag type="info" size="mini" v-if="scope.row.is_online=='offline'">离线</el-tag>
					</template>
				</el-table-column>
				<!--                <el-table-column :show-overflow-tooltip="true"
                    prop="serial_number"
                    label="大修编号">
                </el-table-column> -->
				<el-table-column :show-overflow-tooltip="true" prop="phone" label="电话">
				</el-table-column>
				<el-table-column :show-overflow-tooltip="true" prop="nickname" label="姓名">
				</el-table-column>
				<el-table-column :show-overflow-tooltip="true" prop="branch_1" label="单位">
				</el-table-column>
				<el-table-column prop="branch_1" label="一级部门">
				</el-table-column>
				<el-table-column prop="branch_2" label="二级部门">
				</el-table-column>
				<el-table-column prop="branch_3" label="三级部门">
				</el-table-column>
				<el-table-column prop="branch_4" label="四级部门">
				</el-table-column>
				<el-table-column :show-overflow-tooltip="true" prop="job" label="职务">
				</el-table-column>
				<el-table-column :show-overflow-tooltip="true" label="操作">
					<template slot-scope="scope">
						<el-button type="success" size="mini" @click="bind(scope.row)" v-if="!scope.row.imei">绑定
						</el-button>
						<el-button type="danger" size="mini" @click="unbind(scope.row)" v-if="scope.row.imei">解绑
						</el-button>
						<el-button type="danger" size="mini" @click="delimei(scope.row)" v-if="scope.row.imei">删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagenum"
				:page-sizes="[5,10,15,20]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper"
				:total="total">
			</el-pagination>
		</el-card>
		<el-dialog title="绑定设备" :visible.sync="bindVisible" width="50%">
			<div>
				<el-form ref="form" label-width="120px">
					<el-form-item label="电话号码">
						<el-input v-model="bindPhone"></el-input>
					</el-form-item>
					<el-form-item label="IMEI">
						<el-input v-model="bindImei"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="info" @click="closeBind">取消</el-button>
				<el-button type="primary" @click="bindEquipment">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog title="批量绑定设备" :visible.sync="batchBindVisible" width="50%">
			<div>
				<el-form ref="form" label-width="120px">
					<el-form-item label="EXCEL模板">
						<el-button type="success" size="small">
							<el-link :href="publicUrl+'/public/excel/批量绑定设备.xlsx'">批量绑定设备模板</el-link>
						</el-button>
					</el-form-item>
					<el-form-item label="上传EXCEL">
						<el-upload class="upload-demo" action="" :http-request="excelBatchBind">
							<el-button size="small" type="primary">上传</el-button>
						</el-upload>
					</el-form-item>
					<el-form-item label="所属部门">
						<el-cascader v-model="selectedDepartmentBind" :options="p_data" :props="cascaderProps"
							@change="bindDepartment" :clearable="true" :change-on-select="true">
						</el-cascader>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="info" @click="closeBatchBind">取消</el-button>
				<el-button type="primary" @click="batch_bind">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog title="批量解绑设备" :visible.sync="batchUnbindVisible" width="50%">
			<div>
				<el-form ref="form" label-width="120px">
					<el-form-item label="EXCEL模板">
						<el-button type="success" size="small">
							<el-link :href="publicUrl+'/public/excel/批量解绑设备.xlsx'">批量绑定设备模板</el-link>
						</el-button>
					</el-form-item>
					<el-form-item label="上传EXCEL">
						<el-upload class="upload-demo" action="" :http-request="excelBatchUnbind">
							<el-button size="small" type="primary">上传</el-button>
						</el-upload>
					</el-form-item>
					<el-form-item label="所属部门">
						<el-cascader v-model="selectedDepartmentUnbind" :options="p_data" :props="cascaderProps"
							@change="unbindDepartment" :clearable="true" :change-on-select="true">
						</el-cascader>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="info" @click="closeBatchUnind">取消</el-button>
				<el-button type="primary" @click="batch_unbind">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	import axios from 'axios';
	import {bian_log} from '../api/log'
	import {
		remove_personnel,
	} from '../api/personnel'
	import server_url from '../utils/baseUrl'
	import {
		department_list
	} from '../api/department'
	import {
		equipment_list,
		bind_equipment,
		unbind_equipment,
		batch_bind_equipment,
		batch_unbind_equipment
	} from '../api/equipment'
	export default {
		data() {
			return {
				company: '',
				companyData: [],
				branchArr: [],
				searchImei: '',
				department1List: [],
				department2List: [],
				searchDepartment1: null,
				searchDepartment2: null,
				personnelLink: '',
				publicUrl: server_url,
				equipmentData: [],
				pagenum: 1,
				pagesize: 10,
				total: 0,
				bindVisible: false,
				bindPhone: '',
				bindImei: '',
				batchBindVisible: false,
				selectedDepartmentBind: [],
				p_data: [],
				bindFile: '',
				cascaderProps: {
					value: 'branch',
					label: 'branch',
					children: 'children',
				},
				bindBranch1: null,
				bindBranch2: null,
				batchUnbindVisible: false,
				unbindFile: '',
				selectedDepartmentUnbind: [],
				unbindBranch1: null,
				unbindBranch2: null,
			};
		},
		computed: {},
		methods: {
			departmentList() {
				department_list(this.company, this.searchDepartment1, this.searchDepartment2).then(res => {
					if (res.status != 200) {
						return this.$message.error('获取部门列表失败')
					}
					let branch = res.data.branch;
					branch.forEach(item => {
						item.children = JSON.parse(item.children)
					})
					this.companyData = branch;
					this.department1List = branch;
					//管理员部门查询筛选权限
					let branch_content = sessionStorage.getItem('branch-content')
					let branch_level = sessionStorage.getItem('level')
					let branchArr = [];
					if (branch_level == 1) {
						this.branchArr = []
					} else {
						branchArr = branch_content.split('|');
						this.branchArr = branchArr;
					}
					if (branchArr[0]) {
						this.searchDepartment1 = branchArr[0]
						this.department1List.forEach(item => {
							if (item.branch == this.searchDepartment1) {
								this.department2List = item.children
							}
						})
					}
					if (branchArr[1]) {
						this.searchDepartment2 = branchArr[1]
					}
					this.equipmentList()
					//管理员部门添加人员权限
					this.selectedDepartmentBind = branchArr;
					let p_data = [];
					branch.forEach(item => {
						if (branchArr[0]) {
							if (item.branch == branchArr[0]) {
								let copy = JSON.stringify(item)
								p_data.push(JSON.parse(copy))
								p_data[0].disabled = true;
								if (branchArr[1]) {
									p_data[0].children = [];
									item.children.forEach(t => {
										if (t.branch == branchArr[1]) {
											let copy = JSON.stringify(t)
											p_data[0].children.push(JSON.parse(copy))
											p_data[0].children[0].disabled = true;
										}
									})
								}
							}
						}
					})
					//是否是超级管理员
					if (branch_level == 1) {
						this.p_data = branch
					} else {
						this.p_data = p_data
					}
				})
			},
			equipmentList() {
				const loading = this.$loading({
					lock: true,
					text: '请稍等,数据加载中...',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				equipment_list(this.company, this.searchImei, this.searchDepartment1, this.searchDepartment2, this.pagenum,
					this.pagesize).then(res => {
					if (res.status != 200) {
						return this.$message.error('获取人员列表失败')
					}
					this.equipmentData = res.data.equipmentData;
					let watch_version = sessionStorage.getItem('version');
					if(watch_version && watch_version == "1"){
						for(let i in this.equipmentData){
							this.equipmentData[i].is_online = "offline";
							if(!this.equipmentData[i].imei){
								continue;
							}
							axios.post('http://baxztech.com:18084/api/imei_status', {
								"imei_list": [this.equipmentData[i].imei]
							}).then(lineres=>{
								if(lineres?.data?.data?.imei_status.length) {
									let line_statu = lineres.data.data.imei_status[0].status;
									if(line_statu == 1){
										this.equipmentData[i].is_online = "online";
									}
								}
							})
						}
					}
					this.total = res.data.total;
					loading.close();
				}).catch(err => {
					loading.close();
				})
			},
			handleSizeChange(newSize) {
				this.pagesize = newSize;
				this.equipmentList();
			},
			handleCurrentChange(newPage) {
				this.pagenum = newPage;
				this.equipmentList();
			},
			choiceA() {
				this.searchDepartment2 = null
				this.department2List = []
				this.department1List.forEach(item => {
					if (item.branch == this.searchDepartment1) {
						this.department2List = item.children
					}
				})
			},
			reset() {
				this.searchImei = '';
				this.searchDepartment1 = null;
				this.searchDepartment2 = null;
				this.pagenum = 1;
				this.equipmentList()
			},
			search() {
				this.equipmentList()
			},
			addEquipment() {
				this.bindVisible = true;
			},
			closeBind() {
				this.bindVisible = false;
				this.bindImei = '';
				this.bindPhone = '';
			},
			bindEquipment() {
				if (this.bindPhone == '') {
					return this.$message.error('请输入电话号')
				}
				if (this.bindImei == '') {
					return this.$message.error('请输入IMEI')
				}
				const regex = /^1[3456789]\d{9}$/;
				if (!regex.test(this.bindPhone)) {
					return this.$message.error('请输入正确的电话号');
				}
				const regexImei = /^[0-9]*$/;
				if (!regexImei.test(this.bindImei) || this.bindImei.length < 6) {
					return this.$message.error('请输入正确的IMEI号');
				}
				bind_equipment(this.bindPhone, this.bindImei).then(res => {
					if (res.status == 400) {
						return this.$message.error('无此人员，请先添加人员');
					}
					if (res.status != 200) {
						return this.$message.error('绑定失败')
					}
					let role = '';
					let level = sessionStorage.getItem('level');
					if (level == 1) {
						role = '超级管理员'
					}
					if (level == 2) {
						role = '单位管理员'
					}
					if (level == 3) {
						role = '班组管理员'
					}
					this.$message.success('绑定成功');
					bian_log(`绑定设备${this.bindImei}`)
					this.bindVisible = false;
					this.bindImei = '';
					this.bindPhone = '';
					this.search()
				})
			},
			bind(row) {
				this.bindPhone = row.phone;
				this.bindVisible = true;
			},
			delimei(row) {
				this.$confirm('此操作将删除该设备', '是否确定删除', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					remove_personnel(row.company,row.id).then(res => {
						if (res.status != 200) {
							return this.$message.error('删除失败');
						}
						bian_log(`删除设备${row.imei}`)
						this.$message.success('删除成功！')
						this.search()
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				});
			},
			unbind(row) {
				this.$confirm('此操作将解绑该设备', '是否确定解绑', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					unbind_equipment(row.imei).then(res => {
						if (res.status != 200) {
							return this.$message.error('解绑失败');
						}
						let role = '';
						let level = sessionStorage.getItem('level');
						if (level == 1) {
							role = '超级管理员'
						}
						if (level == 2) {
							role = '单位管理员'
						}
						if (level == 3) {
							role = '班组管理员'
						}
						bian_log(`解绑设备${row.imei}`)
						this.$message.success('解绑成功')
						this.search()
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消解绑'
					});
				});
			},
			batchBind() {
				this.batchBindVisible = true;
			},
			batch_bind() {
				if (this.selectedDepartmentBind[0]) {
					this.bindBranch1 = this.selectedDepartmentBind[0]
				}
				if (this.selectedDepartmentBind[1]) {
					this.bindBranch2 = this.selectedDepartmentBind[1]
				}
				if (this.bindFile == '') {
					return this.$message.error('请上传excel')
				}
				// if(!this.bindBranch2){
				//     return this.$message.error('请选择班组')
				// }
				let formData = new FormData()
				formData.append('excel', this.bindFile)
				formData.append('company', this.company)
				formData.append('branch1', this.bindBranch1)
				formData.append('branch2', this.bindBranch2)
				batch_bind_equipment(formData).then(res => {
					if (res.status == 400) {
						return this.$message.error('请填写excel内容')
					}
					if (res.status == 401) {
						return this.$message.error('请先添加人员')
					}
					if (res.status == 402) {
						return this.$message.error('不能绑定选定部门以外的设备')
					}
					if (res.status != 200) {
						return this.$message.error('绑定失败')
					}
					let role = '';
					let level = sessionStorage.getItem('level');
					if (level == 1) {
						role = '超级管理员'
					}
					if (level == 2) {
						role = '单位管理员'
					}
					if (level == 3) {
						role = '班组管理员'
					}
					bian_log(`批量绑定设备`)
					this.batchBindVisible = false;
					this.$message.success('绑定成功')
					this.equipmentList()
				})
			},
			closeBatchBind() {
				this.batchBindVisible = false;
			},
			excelBatchBind(params) {
				this.bindFile = params.file
			},
			bindDepartment() {
				if (this.selectedDepartmentBind.length == 1) {
					this.bindBranch2 = null
				}
				if (this.selectedDepartmentBind[0]) {
					this.bindBranch1 = this.selectedDepartmentBind[0]
				}
				if (this.selectedDepartmentBind[1]) {
					this.bindBranch2 = this.selectedDepartmentBind[1]
				}
			},
			excelBatchUnbind(params) {
				this.unbindFile = params.file
			},
			unbindDepartment() {
				if (this.selectedDepartmentUnbind.length == 1) {
					this.unbindBranch2 = null
				}
				if (this.selectedDepartmentUnbind[0]) {
					this.unbindBranch1 = this.selectedDepartmentUnbind[0]
				}
				if (this.selectedDepartmentUnbind[1]) {
					this.unbindBranch2 = this.selectedDepartmentUnbind[1]
				}
			},
			batchUnbind() {
				this.batchUnbindVisible = true;
			},
			closeBatchUnind() {
				this.batchUnbindVisible = false;
			},
			batch_unbind() {
				if (this.selectedDepartmentUnbind[0]) {
					this.unbindBranch1 = this.selectedDepartmentUnbind[0]
				}
				if (this.selectedDepartmentUnbind[1]) {
					this.unbindBranch2 = this.selectedDepartmentUnbind[1]
				}
				if (this.unbindFile == '') {
					return this.$message.error('请上传excel')
				}
				// if(!this.unbindBranch2){
				//     return this.$message.error('请选择班组')
				// }
				let formData = new FormData()
				formData.append('excel', this.unbindFile)
				formData.append('company', this.company)
				formData.append('branch1', this.unbindBranch1)
				formData.append('branch2', this.unbindBranch2)
				batch_unbind_equipment(formData).then(res => {
					if (res.status == 400) {
						return this.$message.error('请填写excel内容')
					}
					if (res.status == 402) {
						return this.$message.error('无法解绑选定部门以外的设备')
					}
					if (res.status != 200) {
						return this.$message.error('解绑失败')
					}
					let role = '';
					let level = sessionStorage.getItem('level');
					if (level == 1) {
						role = '超级管理员'
					}
					if (level == 2) {
						role = '单位管理员'
					}
					if (level == 3) {
						role = '班组管理员'
					}
					bian_log(`批量解绑设备`)
					// hedian_log('设备管理',`批量解绑设备`,sessionStorage.getItem('name'),role,new Date().getTime())
					this.batchUnbindVisible = false;
					this.$message.success('解绑成功')
					this.equipmentList()
				})
			}
		},
		created() {
			let company = sessionStorage.getItem('company')
			this.company = company;
			this.departmentList()
		},
	};
</script>
<style lang='less' scoped>
</style>
